<template>
    <zw-sidebar @shown="shown" :title="$t('offering.address.'+payload.type+'_address')">
        <validation-observer tag="div" ref="observer">
            <b-overlay :show="loading" no-wrap></b-overlay>
            <div v-if="!loading">
                <b-row>
                    <b-col cols="12">
                        <zw-input-group name="company"
                                        v-model="form.company"
                                        :label-prefix="labelPrefix"
                        ></zw-input-group>
                    </b-col>
                    <b-col cols="12">
                        <zw-input-group name="firstname"
                                        v-model="form.firstname"
                                        :label-prefix="labelPrefix"
                        ></zw-input-group>
                    </b-col>
                    <b-col cols="12">
                        <zw-input-group name="middlename"
                                        v-model="form.middlename"
                                        :label-prefix="labelPrefix"
                        ></zw-input-group>
                    </b-col>
                    <b-col cols="12">
                        <zw-input-group name="lastname"
                                        v-model="form.lastname"
                                        :label-prefix="labelPrefix"
                        ></zw-input-group>
                    </b-col>
                    <b-col cols="12">
                        <zw-input-group name="care_of_address"
                                        v-model="form.care_of_address"
                                        :label-prefix="labelPrefix"
                        ></zw-input-group>
                    </b-col>
                    <b-col cols="12">
                        <zw-input-group name="street"
                                        v-model="form.street"
                                        :label-prefix="labelPrefix"
                        ></zw-input-group>
                    </b-col>
                    <b-col cols="12">
                        <zw-input-group name="plz"
                                        v-model="form.plz"
                                        :label-prefix="labelPrefix"
                        ></zw-input-group>
                    </b-col>
                    <b-col cols="12">
                        <zw-input-group name="city"
                                        v-model="form.city"
                                        :label-prefix="labelPrefix"
                        ></zw-input-group>
                    </b-col>
                    <b-col cols="12">
                        <zw-select-group v-model="form.country_iso"
                                         :options="getCountries()"
                                         name="country_iso"
                                         :label-prefix="labelPrefix"
                                         text-field="name"
                                         value-field="iso2"
                        ></zw-select-group>
                    </b-col>
                    <b-col cols="12" v-if="payload.type=='shipping'">
                        <zw-input-group name="packstation_nr"
                                        v-model="form.packstation_nr"
                                        :label-prefix="labelPrefix"
                                        type="number"
                        ></zw-input-group>
                    </b-col>
                    <b-col cols="12" v-if="form.packstation_nr">
                        <zw-input-group name="postnumber"
                                        v-model="form.postnumber"
                                        :label-prefix="labelPrefix"
                                        type="number"
                        ></zw-input-group>
                    </b-col>
                    <b-col cols="12">
                        <zw-input-group name="email"
                                        v-model="form.email"
                                        :label-prefix="labelPrefix"
                        ></zw-input-group>
                    </b-col>
                    <b-col cols="12">
                        <zw-input-group name="phone"
                                        v-model="form.phone"
                                        :label-prefix="labelPrefix"
                        ></zw-input-group>
                    </b-col>
                    <b-col cols="12">
                        <zw-input-group name="additional"
                                        v-model="form.additional"
                                        :label-prefix="labelPrefix"
                        ></zw-input-group>
                    </b-col>
                    <b-col cols="12">
                        <zw-input-group name="vat_id"
                                        v-model="form.vat_id"
                                        :label-prefix="labelPrefix"
                        ></zw-input-group>
                    </b-col>
                </b-row>

                <b-row>
                    <b-col sm="12" class="text-sm-right">
                        <b-button block @click="onSubmit()" variant="primary">
                            {{ $t('common.button.save') }}
                        </b-button>
                    </b-col>
                </b-row>
            </div>
        </validation-observer>
    </zw-sidebar>
</template>

<script>
import {mapGetters} from 'vuex'
import commonSave from '@/bundles/erika_common_bundle/mixins/common-save'

export default {
    name: 'OfferingAddressModal',
    mixins: [commonSave],
    data() {
        return {
            loading: true,
            payload: {},
            callback: null,
            defaultForm: {},
            form: {},
            labelPrefix: 'offering.address.',
        }
    },
    methods: {
        ...mapGetters('CommonData', ['getCountries']),
        ...mapGetters('Offering', ['getAddress']),
        shown() {
            this.loading = false
            const address = this.$store.dispatch('Offering/fetchAddress', {
                id: this.payload.offeringId,
                type: this.payload.type
            })

            Promise.all([address])
                .then(() => {
                    this.form = {
                        ...JSON.parse(JSON.stringify(this.defaultForm)),
                        ...JSON.parse(JSON.stringify(this.getAddress()))
                    }
                    this.$refs['observer'].reset()
                })
                .finally(() => {
                    this.loading = false;
                })
        },
        onSubmit() {
            this.$refs['observer'].validate().then(valid => {
                if (valid) {
                    this.loading = true
                    this.$store.dispatch('Offering/saveAddress', this.form)
                        .then((response) => {
                            this.commonAfterSave(response)
                        })
                        .catch(errors => {
                            this.$refs['observer'].setErrors(errors)
                            this.showValidationError()
                        })
                        .finally(() => {
                            this.loading = false
                        })
                } else {
                    this.showValidationError()
                }
            })
        },
    },
}
</script>